<script lang="ts">
	import type { LayoutData } from './$houdini';
	import { dev } from '$app/environment';
	import { Head } from '$lib/sections/layouts';
	import { pageStatus } from '$stores';

	export let data: LayoutData;

	let { routeInfo } = data;

	$: if (routeInfo?.path) {
		let currentState = 'live';
		let load = true;

		for (const state in $pageStatus.map) {
			if (Object.hasOwnProperty.call($pageStatus.map, state)) {
				const list = $pageStatus.map[state];

				if (list.includes(routeInfo.path)) {
					currentState = state;
					if (currentState === 'dev') load = dev;
					continue;
				}
			}
		}

		$pageStatus.state = currentState;
		$pageStatus.load = load;
	}
</script>

<Head />
<slot />

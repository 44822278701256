<script lang="ts">
	import { page } from '$app/stores';

	const { globalInfo } = $page.data;
	$: ({ routeInfo } = $page.data);

	const errorTitleMap: Record<number | string, string> = {
		404: 'Page Not Found',
		500: 'Something Unexpected Happened',
		default: $page.error?.message || 'Error'
	};

	$: pageTitle = routeInfo.pageTitle || globalInfo.settings.defaultPageTitle;
	$: metaDescription = routeInfo.metaDescription || '';
	$: {
		if ($page.error) {
			pageTitle = `${$page.status}: ${errorTitleMap[$page.status] || errorTitleMap.default} ${globalInfo.settings.titleSuffix}`;
		}
	}
</script>

<svelte:head>
	<title>{pageTitle}</title>
	{#if metaDescription}<meta name="description" content={metaDescription} />{/if}
</svelte:head>

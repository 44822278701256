export default {
    "name": "RouteQuery",
    "kind": "HoudiniQuery",
    "hash": "fd718cba865e516f2da77ab88ac7e7aaed47483753def28850052397055b6b26",

    "raw": `query RouteQuery($path: String!, $lowerpath: String!, $routeIdArray: [String]) {
  redirect: redirectCollection(
    limit: 1
    where: {OR: [{from: $path}, {from: $lowerpath}]}
  ) {
    total
    skip
    limit
    items {
      from
      to
      statusCode
    }
  }
  route: siteMapCollection(
    limit: 1
    where: {OR: [{path: $path}, {routeId_in: $routeIdArray}]}
  ) {
    total
    skip
    limit
    items {
      sys {
        id
      }
      name
      pageTitle
      metaDescription
      useTitleTemplate
      isPageTemplate
      routeId
      path
      parent {
        sys {
          id
        }
        pageTitle
        path
      }
      detailViewCollection(limit: 50) {
        total
        items {
          ... on DetailViewHero {
            __typename
            heading
            text
            baseColor
            mobileBaseColor
            textColor
            mobileTextColor
            imageNew {
              __typename
              alt
              caption
              desktop
              mobile
              externalDesktopSrc
              externalMobileSrc
            }
            image
            mobileImage
          }
          ... on DetailViewIntro {
            __typename
            heading
            body {
              json
            }
          }
          ... on DetailViewH2 {
            __typename
            heading
            body {
              json
            }
            image
            imagePosition
            imageColumnWidth
            h3GroupsCollection(limit: 10) {
              __typename
              total
              skip
              limit
              items {
                ... on DetailViewH3Group {
                  __typename
                  h3Collection(limit: 10) {
                    total
                    skip
                    limit
                    items {
                      ... on DetailViewH3 {
                        __typename
                        heading
                        body {
                          json
                        }
                      }
                    }
                  }
                  image
                  imagePosition
                  imageColumnWidth
                }
                __typename
              }
            }
          }
          ... on Coupon {
            __typename
            detail
            image
          }
          ... on DetailViewContent {
            __typename
            type
            heading
            body {
              json
            }
            imagesCollection(limit: 10) {
              __typename
              total
              skip
              limit
              items {
                ... on ContentImage {
                  __typename
                  alt
                  caption
                  desktop
                  mobile
                }
              }
            }
            imagePosition
          }
          __typename
        }
      }
      relatedServices: relatedServicesCollection(limit: 10) {
        items {
          sys {
            id
          }
          name
          page {
            path
          }
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "redirect": {
                "type": "RedirectCollection",
                "keyRaw": "redirect(limit: 1, where: {OR: [{from: $path}, {from: $lowerpath}]})",
                "nullable": true,

                "selection": {
                    "fields": {
                        "total": {
                            "type": "Int",
                            "keyRaw": "total",
                            "visible": true
                        },

                        "skip": {
                            "type": "Int",
                            "keyRaw": "skip",
                            "visible": true
                        },

                        "limit": {
                            "type": "Int",
                            "keyRaw": "limit",
                            "visible": true
                        },

                        "items": {
                            "type": "Redirect",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "from": {
                                        "type": "String",
                                        "keyRaw": "from",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "to": {
                                        "type": "String",
                                        "keyRaw": "to",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "statusCode": {
                                        "type": "Int",
                                        "keyRaw": "statusCode",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "route": {
                "type": "SiteMapCollection",
                "keyRaw": "route(limit: 1, where: {OR: [{path: $path}, {routeId_in: $routeIdArray}]})",
                "nullable": true,

                "selection": {
                    "fields": {
                        "total": {
                            "type": "Int",
                            "keyRaw": "total",
                            "visible": true
                        },

                        "skip": {
                            "type": "Int",
                            "keyRaw": "skip",
                            "visible": true
                        },

                        "limit": {
                            "type": "Int",
                            "keyRaw": "limit",
                            "visible": true
                        },

                        "items": {
                            "type": "SiteMap",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "sys": {
                                        "type": "Sys",
                                        "keyRaw": "sys",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "String",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "pageTitle": {
                                        "type": "String",
                                        "keyRaw": "pageTitle",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "metaDescription": {
                                        "type": "String",
                                        "keyRaw": "metaDescription",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "useTitleTemplate": {
                                        "type": "Boolean",
                                        "keyRaw": "useTitleTemplate",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "isPageTemplate": {
                                        "type": "Boolean",
                                        "keyRaw": "isPageTemplate",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "routeId": {
                                        "type": "String",
                                        "keyRaw": "routeId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "parent": {
                                        "type": "SiteMap",
                                        "keyRaw": "parent",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "sys": {
                                                    "type": "Sys",
                                                    "keyRaw": "sys",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "String",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "pageTitle": {
                                                    "type": "String",
                                                    "keyRaw": "pageTitle",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "detailViewCollection": {
                                        "type": "SiteMapDetailViewCollection",
                                        "keyRaw": "detailViewCollection(limit: 50)",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "total": {
                                                    "type": "Int",
                                                    "keyRaw": "total",
                                                    "visible": true
                                                },

                                                "items": {
                                                    "type": "SiteMapDetailViewItem",
                                                    "keyRaw": "items",

                                                    "selection": {
                                                        "abstractFields": {
                                                            "fields": {
                                                                "DetailViewHero": {
                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    },

                                                                    "heading": {
                                                                        "type": "String",
                                                                        "keyRaw": "heading",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "text": {
                                                                        "type": "String",
                                                                        "keyRaw": "text",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "baseColor": {
                                                                        "type": "String",
                                                                        "keyRaw": "baseColor",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "mobileBaseColor": {
                                                                        "type": "String",
                                                                        "keyRaw": "mobileBaseColor",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "textColor": {
                                                                        "type": "String",
                                                                        "keyRaw": "textColor",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "mobileTextColor": {
                                                                        "type": "String",
                                                                        "keyRaw": "mobileTextColor",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "imageNew": {
                                                                        "type": "ContentImage",
                                                                        "keyRaw": "imageNew",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "__typename": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "__typename",
                                                                                    "visible": true
                                                                                },

                                                                                "alt": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "alt",
                                                                                    "nullable": true,
                                                                                    "visible": true
                                                                                },

                                                                                "caption": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "caption",
                                                                                    "nullable": true,
                                                                                    "visible": true
                                                                                },

                                                                                "desktop": {
                                                                                    "type": "JSON",
                                                                                    "keyRaw": "desktop",
                                                                                    "nullable": true,
                                                                                    "visible": true
                                                                                },

                                                                                "mobile": {
                                                                                    "type": "JSON",
                                                                                    "keyRaw": "mobile",
                                                                                    "nullable": true,
                                                                                    "visible": true
                                                                                },

                                                                                "externalDesktopSrc": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "externalDesktopSrc",
                                                                                    "nullable": true,
                                                                                    "visible": true
                                                                                },

                                                                                "externalMobileSrc": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "externalMobileSrc",
                                                                                    "nullable": true,
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "image": {
                                                                        "type": "JSON",
                                                                        "keyRaw": "image",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "mobileImage": {
                                                                        "type": "JSON",
                                                                        "keyRaw": "mobileImage",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    }
                                                                },

                                                                "DetailViewIntro": {
                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    },

                                                                    "heading": {
                                                                        "type": "String",
                                                                        "keyRaw": "heading",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "body": {
                                                                        "type": "DetailViewIntroBody",
                                                                        "keyRaw": "body",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "json": {
                                                                                    "type": "JSON",
                                                                                    "keyRaw": "json",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    }
                                                                },

                                                                "DetailViewH2": {
                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    },

                                                                    "heading": {
                                                                        "type": "String",
                                                                        "keyRaw": "heading",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "body": {
                                                                        "type": "DetailViewH2Body",
                                                                        "keyRaw": "body",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "json": {
                                                                                    "type": "JSON",
                                                                                    "keyRaw": "json",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "image": {
                                                                        "type": "JSON",
                                                                        "keyRaw": "image",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "imagePosition": {
                                                                        "type": "String",
                                                                        "keyRaw": "imagePosition",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "imageColumnWidth": {
                                                                        "type": "String",
                                                                        "keyRaw": "imageColumnWidth",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "h3GroupsCollection": {
                                                                        "type": "DetailViewH2H3GroupsCollection",
                                                                        "keyRaw": "h3GroupsCollection(limit: 10)",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "__typename": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "__typename",
                                                                                    "visible": true
                                                                                },

                                                                                "total": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "total",
                                                                                    "visible": true
                                                                                },

                                                                                "skip": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "skip",
                                                                                    "visible": true
                                                                                },

                                                                                "limit": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "limit",
                                                                                    "visible": true
                                                                                },

                                                                                "items": {
                                                                                    "type": "DetailViewH2H3GroupsItem",
                                                                                    "keyRaw": "items",

                                                                                    "selection": {
                                                                                        "abstractFields": {
                                                                                            "fields": {
                                                                                                "DetailViewH3Group": {
                                                                                                    "__typename": {
                                                                                                        "type": "String",
                                                                                                        "keyRaw": "__typename",
                                                                                                        "visible": true
                                                                                                    },

                                                                                                    "h3Collection": {
                                                                                                        "type": "DetailViewH3GroupH3Collection",
                                                                                                        "keyRaw": "h3Collection(limit: 10)",
                                                                                                        "nullable": true,

                                                                                                        "selection": {
                                                                                                            "fields": {
                                                                                                                "total": {
                                                                                                                    "type": "Int",
                                                                                                                    "keyRaw": "total",
                                                                                                                    "visible": true
                                                                                                                },

                                                                                                                "skip": {
                                                                                                                    "type": "Int",
                                                                                                                    "keyRaw": "skip",
                                                                                                                    "visible": true
                                                                                                                },

                                                                                                                "limit": {
                                                                                                                    "type": "Int",
                                                                                                                    "keyRaw": "limit",
                                                                                                                    "visible": true
                                                                                                                },

                                                                                                                "items": {
                                                                                                                    "type": "DetailViewH3",
                                                                                                                    "keyRaw": "items",

                                                                                                                    "selection": {
                                                                                                                        "fields": {
                                                                                                                            "__typename": {
                                                                                                                                "type": "String",
                                                                                                                                "keyRaw": "__typename",
                                                                                                                                "visible": true
                                                                                                                            },

                                                                                                                            "heading": {
                                                                                                                                "type": "String",
                                                                                                                                "keyRaw": "heading",
                                                                                                                                "nullable": true,
                                                                                                                                "visible": true
                                                                                                                            },

                                                                                                                            "body": {
                                                                                                                                "type": "DetailViewH3Body",
                                                                                                                                "keyRaw": "body",
                                                                                                                                "nullable": true,

                                                                                                                                "selection": {
                                                                                                                                    "fields": {
                                                                                                                                        "json": {
                                                                                                                                            "type": "JSON",
                                                                                                                                            "keyRaw": "json",
                                                                                                                                            "visible": true
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                },

                                                                                                                                "visible": true
                                                                                                                            }
                                                                                                                        }
                                                                                                                    },

                                                                                                                    "visible": true
                                                                                                                }
                                                                                                            }
                                                                                                        },

                                                                                                        "visible": true
                                                                                                    },

                                                                                                    "image": {
                                                                                                        "type": "JSON",
                                                                                                        "keyRaw": "image",
                                                                                                        "nullable": true,
                                                                                                        "visible": true
                                                                                                    },

                                                                                                    "imagePosition": {
                                                                                                        "type": "String",
                                                                                                        "keyRaw": "imagePosition",
                                                                                                        "nullable": true,
                                                                                                        "visible": true
                                                                                                    },

                                                                                                    "imageColumnWidth": {
                                                                                                        "type": "String",
                                                                                                        "keyRaw": "imageColumnWidth",
                                                                                                        "nullable": true,
                                                                                                        "visible": true
                                                                                                    }
                                                                                                }
                                                                                            },

                                                                                            "typeMap": {}
                                                                                        },

                                                                                        "fields": {
                                                                                            "__typename": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "__typename",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "abstract": true,
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    }
                                                                },

                                                                "Coupon": {
                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    },

                                                                    "detail": {
                                                                        "type": "String",
                                                                        "keyRaw": "detail",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "image": {
                                                                        "type": "JSON",
                                                                        "keyRaw": "image",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    }
                                                                },

                                                                "DetailViewContent": {
                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    },

                                                                    "type": {
                                                                        "type": "String",
                                                                        "keyRaw": "type",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "heading": {
                                                                        "type": "String",
                                                                        "keyRaw": "heading",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    },

                                                                    "body": {
                                                                        "type": "DetailViewContentBody",
                                                                        "keyRaw": "body",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "json": {
                                                                                    "type": "JSON",
                                                                                    "keyRaw": "json",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "imagesCollection": {
                                                                        "type": "DetailViewContentImagesCollection",
                                                                        "keyRaw": "imagesCollection(limit: 10)",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "__typename": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "__typename",
                                                                                    "visible": true
                                                                                },

                                                                                "total": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "total",
                                                                                    "visible": true
                                                                                },

                                                                                "skip": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "skip",
                                                                                    "visible": true
                                                                                },

                                                                                "limit": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "limit",
                                                                                    "visible": true
                                                                                },

                                                                                "items": {
                                                                                    "type": "ContentImage",
                                                                                    "keyRaw": "items",

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "__typename": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "__typename",
                                                                                                "visible": true
                                                                                            },

                                                                                            "alt": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "alt",
                                                                                                "nullable": true,
                                                                                                "visible": true
                                                                                            },

                                                                                            "caption": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "caption",
                                                                                                "nullable": true,
                                                                                                "visible": true
                                                                                            },

                                                                                            "desktop": {
                                                                                                "type": "JSON",
                                                                                                "keyRaw": "desktop",
                                                                                                "nullable": true,
                                                                                                "visible": true
                                                                                            },

                                                                                            "mobile": {
                                                                                                "type": "JSON",
                                                                                                "keyRaw": "mobile",
                                                                                                "nullable": true,
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "imagePosition": {
                                                                        "type": "String",
                                                                        "keyRaw": "imagePosition",
                                                                        "nullable": true,
                                                                        "visible": true
                                                                    }
                                                                }
                                                            },

                                                            "typeMap": {}
                                                        },

                                                        "fields": {
                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "abstract": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "relatedServices": {
                                        "type": "SiteMapRelatedServicesCollection",
                                        "keyRaw": "relatedServices(limit: 10)",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "items": {
                                                    "type": "Service",
                                                    "keyRaw": "items",

                                                    "selection": {
                                                        "fields": {
                                                            "sys": {
                                                                "type": "Sys",
                                                                "keyRaw": "sys",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "String",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "page": {
                                                                "type": "SiteMap",
                                                                "keyRaw": "page",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "path": {
                                                                            "type": "String",
                                                                            "keyRaw": "path",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "path": "String",
            "lowerpath": "String",
            "routeIdArray": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=1966f667d3da8fe3b79cb12b1677d600dd3986da58d8bef20022ba73b875638b";
import type { AfterLoadEvent, RouteQueryVariables } from './$houdini'

import { redirect, error } from '@sveltejs/kit'
import { createRouteIdArray } from '$lib/functions/utils'

export const _RouteQueryVariables: RouteQueryVariables = ({ url, route }) => {
	const routeIdArray = createRouteIdArray(route.id)
	const variables = { path: url.pathname, lowerpath: url.pathname.toLowerCase(), routeIdArray }

	return variables
}

export async function _houdini_afterLoad({ data, event }: AfterLoadEvent) {
	const parent = await event.parent()
	const globalInfo = parent.globalInfo


	if (data?.RouteQuery?.redirect?.items?.[0]) {
		const redirectInfo = data.RouteQuery.redirect.items[0]
		if (typeof redirectInfo?.statusCode === 'number' && typeof redirectInfo?.to === 'string') redirect(redirectInfo.statusCode, redirectInfo.to)
		else console.log('redirectInfo types incorrect',redirectInfo);
	}

	let routeInfo
	let routeError

	if (data.RouteQuery.route?.items?.[0]) {
		routeInfo = { ...data.RouteQuery.route.items[0] }

		routeInfo.pageTitle = routeInfo.pageTitle || ''
		routeInfo.metaDescription = routeInfo.metaDescription || ''
		routeInfo.isPageTemplate = routeInfo.isPageTemplate || false
		routeInfo.path = routeInfo.path || ''

		if (routeInfo.useTitleTemplate) routeInfo.pageTitle += ' ' + globalInfo.settings.titleSuffix
		if (routeInfo.isPageTemplate) {
			// console.log('1 routeInfo', routeInfo)
		} else {
			// console.log('2 routeInfo', routeInfo)
			// redirectPath({
			// 	verifiedPath: routeInfo.path,
			// 	event,
			// 	redirect
			// })
		}
	} else {

		routeError = {
			code: 404,
			message: 'Page not found.'
		}
	}

	if (routeError) {
		error(routeError.code, routeError.message);
	}

	return {
		routeInfoType: typeof routeInfo,
		routeInfo,
		currentPath: event.url.pathname
	}
}
